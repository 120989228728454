<!--====================================================
                       HOME-P
======================================================-->
<div id="home-p" class="home-p pages-head2 text-center">
    <div class="container">
      <h1 class="wow fadeInUp" data-wow-delay="0.1s">We understand your industry</h1>
      <p>Finance & Banking</p>
    </div><!--/end container-->
  </div> 

<!--====================================================
                      CAREER-P1
======================================================-->

  <section id="about" class="career-p1 about">
    <div class="container">
      <div class="row"> 

        <div class="col-md-6 img-fluid wow fadeInLeft">
          <div class="career-p1-desc">
            <h4  class="wow slideInLeft" data-wow-delay=".1s" > Finance & Banking</h4>
            <div class="heading-border-light"></div>
            <p>Digital transformation in the banking and financial sector space is a difficult and massive undertaking. As consumer-facing services have to meet emerging consumer expectations, market democratization, and highly sophisticated client requirements. This is then complicated by the need to adhere to stringent regulatory compliance around anti-money laundering, data protection laws, BSA, and others.</p>

            <p>The use of insight-driven digital transformation is crucial to maintaining a competitive edge, but it can also bring challenges. This is no truer than in the complex area of financial services and banking regulatory compliance.</p>
            <p>Strong analytics and consistent user experience are what differentiates the financial institutions that rule the markets. Our finance and banking solutions are devised to rapidly address the market changes and secure new opportunities to outsmart the competitors.</p> 
            <p>Our end-to-end solutions take care of all the hard work so that you could use your precious business time for core competencies that need your input and monitoring.</p>
            <p>Optimize investments, minimize risks, achieve operational efficiency through our segmental services in banking technology, retail and investment banking, corporate banking, cards, wealth management etc.</p>
          </div>
        </div>  
        <div class="col-md-6" >
            <div class="career-p1-himg" >
              <img src="assets/images/industries/financial-banking_industry.jpg" class="img-fluid wow fadeInUp" data-wow-delay="0.1s" alt="">
            </div>
          </div>
      </div>
    </div> 
  </section>  

<!--====================================================
                      CAREER-P2
======================================================-->
  <div class="overlay-career-p2"></div>
  <section id="thought" class="bg-parallax career-p2-bg">
    <div class="container">
      <div id="row" class="row title-bar-career-p2">
        <div class="col-md-4 ">
          <h1 >100%</h1>
        </div>  
        <div class="col-md-8 ">
          <p class="wow fadeInUp " data-wow-delay="0.4s">We offer deep and broad scalable solutions to support your business-critical, finance-focused models. Our system pulls out data from across the organization to deliver you finance intelligence that you can cash on to keep your customers happy, all the while improving the efficiency of your finance and banking verticals.</p>
        </div> 
      </div>
    </div>         
  </section> 

<!--====================================================
                     CAREER-P3
======================================================--> 
  <div class="overlay-career-p3"></div>
   <section id="career-p3">
     <div class="container-fluide">
       <div class="row career-p3-title">
        <div class="col-md-12">
         <h3 class="text-center">Our Solutions</h3>
        </div> 
       </div>
     </div>
   </section> 

   <section class="what-we-do bg-gradiant">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-globe"></i>
                <h6>Portals & Mobile Apps</h6>
                <p class="desc">Responsive web portals and apps for your products and services</p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-users"></i>
                <h6>Customer Management</h6>
                <p class="desc"> Get insight on consumer behaviors & expectations. </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-file-invoice-dollar"></i>
                <h6>Account Management</h6>
                <p class="desc">End to end account processing workflow </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-warehouse"></i>
                <h6>Claims Management</h6>
                <p class="desc">Complete claims processing and approval workflow. </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-tags"></i>
                <h6>Portfolio Management</h6>
                <p class="desc">Comprehensive portfolio management of your customers. </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-comments"></i>
                <h6>Reviews and Feedback</h6>
                <p class="desc">Solutions for customer reviews and Feedback  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>      
  </section> 

<!--====================================================
                       HOME-P
======================================================-->
<div id="home-p" class="home-p pages-head2 text-center">
    <div class="container">
      <h1 class="wow fadeInUp" data-wow-delay="0.1s">We understand your industry</h1>
      <p>Travel & Hospitality</p>
    </div><!--/end container-->
  </div> 

<!--====================================================
                      CAREER-P1
======================================================-->

  <section id="about" class="career-p1 about">
    <div class="container">
      <div class="row"> 

        <div class="col-md-6 img-fluid wow fadeInLeft">
          <div class="career-p1-desc">
            <h4 class="wow slideInLeft" data-wow-delay=".1s">Travel & Hospitality</h4>
            <div class="heading-border-light"></div>
            <p>Digital transformation is on its way to creating a massive disruption in the travel industry. This has become even more apparent in recent years. Companies that want to stay ahead and offer customers the best personalized experiences will have to embrace digital tools and approaches.</p>
            <p>If the travel & hospitality industry's lifecycle is analyzed, any business analyst will notice the changes that have taken place to improve the experiences of everything ranging right from the booking to the check-out. From metasearch to peer-to-peer feedback, the consumers, now, are aware of countless choices when it comes to booking a hotel or managing their stay.</p> 
            <p>Whether it is a niche bread and breakfast inn or it is the world's biggest hotel, disruption characterizes the travel & hospitality sector. And to manage such disruptions, any player of the sector would want to leverage digital technologies to exceed the guest's expectations every time.</p>
            <p>We have a rich experience to provide a range of web solutions that are designed to enable a player of the travel & hospitality industry to deliver an enriching experience. These solutions make the web development players savvy enough to cater to even the most exacting guests.</p>
          </div>
        </div>  
        <div class="col-md-6" >
            <div class="career-p1-himg" >
              <img src="assets/images/industries/Travel-hospitality-industry.jpg" class="img-fluid wow fadeInUp" data-wow-delay="0.1s" alt="">
            </div>
          </div>
      </div>
    </div> 
  </section>  

<!--====================================================
                      CAREER-P2
======================================================-->
  <div class="overlay-career-p2"></div>
  <section id="thought" class="bg-parallax career-p2-bg">
    <div class="container">
      <div id="row" class="row title-bar-career-p2">
        <div class="col-md-4 ">
          <h1 >100%</h1>
        </div>  
        <div class="col-md-8 ">
          <p class="wow fadeInUp " data-wow-delay="0.4s">BizTech offers a range of web solutions to new as well as leading names in the travel & hospitality sector. These solutions reflect the evolution of the travel & hospitality landscape and are designed leveraging the latest technologies.</p>
        </div> 
      </div>
    </div>         
  </section> 

<!--====================================================
                     CAREER-P3
======================================================--> 
  <div class="overlay-career-p3"></div>
   <section id="career-p3">
     <div class="container-fluide">
       <div class="row career-p3-title">
        <div class="col-md-12">
         <h3 class="text-center">Our Solutions</h3>
        </div> 
       </div>
     </div>
   </section> 

   <section class="what-we-do bg-gradiant">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-globe"></i>
                <h6>Websites & Mobile Apps</h6>
                <p class="desc">Responsive websites and apps for seemless experience</p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-users"></i>
                <h6>Customer Management</h6>
                <p class="desc"> Get insight on consumer behaviors & expectations. </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-file-invoice-dollar"></i>
                <h6>Billing Management</h6>
                <p class="desc">End to end billing and payment processing workflow </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-warehouse"></i>
                <h6>Reservation Management</h6>
                <p class="desc">Complete reservation management for your assets. </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-tags"></i>
                <h6>Price and Promotions</h6>
                <p class="desc">On-Demand price management as wells as promotion exeuction for sales. </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-comments"></i>
                <h6>Reviews and Feedback</h6>
                <p class="desc">Solutions for customer reviews and Feedback  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>      
  </section> 

<!--====================================================
                       HOME-P
======================================================-->
<div id="home-p" class="home-p som-head1 text-center">
    <div class="container">
      <h1 class="wow fadeInUp" data-wow-delay="0.1s">We help market Your Business online in Budget</h1>
      <p>Our Services</p>
    </div><!--/end container-->
  </div> 

<!--====================================================
                    BUSINESS-GROWTH-P1
======================================================-->
<section class="ftco-section">
    <div class="container">  
    <div style="display: flex;flex-flow: row;margin: 30px;">
          <div style="width:301px;margin: 10px;padding-right:20px;" class="img-fluid wow fadeInUp">
              <img width="300px" style="width:300px;height:auto;" src="assets/images/solutions/online-marketing-service.png" alt="Digital Transformation">
          </div>
          <div style="width:auto;padding: 20px;" class="img-fluid wow fadeInRight">
   
  
              <h2 class="wow slideInRight" data-wow-delay=".1s">Online Marketing</h2>
              <div class="heading-border-light"></div>
  
  
                <p>Digital marketing encompasses all marketing efforts that use an electronic device or the internet. Businesses leverage digital channels such as search engines, social media, email, and other websites to connect with current and prospective customers.</p>
  
  
  
                <p>It is. In fact, "constant" internet usage among adults increased by 5% in just the last three years, according to Pew Research. And although we say it a lot, the way people shop and buy really has changed along with it — meaning offline marketing isn't as effective as it used to be.</p>
  
  
  
                <p>Marketing has always been about connecting with your audience in the right place and at the right time. Today, that means you need to meet them where they are already spending time: on the internet.</p>
  
                <p>Enter <span style="font-weight: bold;font-style: italic;text-decoration: underline;">digital marketing</span> — in other words, any form of marketing that exists online.</p>
  
  
      </div>
    </div>
  <div >
      <!--====================================================
                      BUSINESS-GROWTH-P1
======================================================-->
<section id="business-growth-p1" class="business-growth-p1 ">
    <div class="container">
      <div class="row title-bar" style="padding: 0px 0px 0px 0px;">
        <div class="col-md-12">
          <h1 class="wow fadeInUp">We committed to helping</h1>
          <div class="heading-border"></div>
          <p class="wow fadeInUp" data-wow-delay="0.4s">When it comes to online marketing, the vast amount of options and ‘things you should be doing’ can seem daunting. We help you evaluate each option's beneifits by looking at your unique business and see what makes the most sense for your business and make the best use of your investment. <br/>
            Call us today!  to learn how we can build a digital marketing strategy that fits your needs and budget.
          </p>
        </div>
      </div>
      <div class="row wow animated fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
        <div class="col-md-3 col-sm-6 service-padding">
            <div class="service-item">
                <div class="service-item-icon"> <i class="fa fa-envelope fa-3x"></i>
                </div>
                <div class="service-item-title">
                    <h3>Email Marketing</h3>
                </div>
                <div class="service-item-desc">
                    <p>Sending personalized message to group(s) of prospects or customers</p> 
                    <div class="content-title-underline-light"></div> 
                </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 service-padding">
            <div class="service-item">
                <div class="service-item-icon"> <i class="fa fa-users fa-3x"></i>
                </div>
                <div class="service-item-title">
                    <h3>Social Media Marketing</h3>
                </div>
                <div class="service-item-desc">
                    <p>Engage with the global audience to grow and build your Brand and Business</p>
                    <div class="content-title-underline-light"></div> 
                </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 service-padding">
            <div class="service-item">
                <div class="service-item-icon"> <i class="fa fa-globe fa-3x"></i>
                </div>
                <div class="service-item-title">
                    <h3>Advertise Online</h3>
                </div>
                <div class="service-item-desc">
                    <p>Target audience already interested in your offering and convert to customers.</p>
                    <div class="content-title-underline-light"></div> 
                </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 service-padding">
            <div class="service-item right-bord">
                <div class="service-item-icon"> <i class="fa fa-search fa-3x"></i>
                </div>
                <div class="service-item-title">
                    <h3>SEO</h3>
                </div>
                <div class="service-item-desc">
                    <p>Drive web traffic, enhance user experience & improve overall web presence.</p>
                    <div class="content-title-underline-light"></div> 
                </div>
            </div>
        </div> 
      </div>
    </div>  
  </section>   
  </div>
  </div>
  </section>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-onlinemarketing',
  templateUrl: './onlinemarketing.component.html',
  styleUrls: ['./onlinemarketing.component.css']
})
export class OnlinemarketingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<!--====================================================
                       HOME-P
======================================================-->
<div id="home-p" class="home-p pages-head2 text-center">
    <div class="container">
      <h1 class="wow fadeInUp" data-wow-delay="0.1s">We understand your industry</h1>
      <p>Advertisements & Marketing</p>
    </div><!--/end container-->
  </div> 

<!--====================================================
                      CAREER-P1
======================================================-->

  <section id="about" class="career-p1 about">
    <div class="container">
      <div class="row"> 

        <div class="col-md-6 img-fluid wow fadeInLeft">
          <div class="career-p1-desc">
            <h4 class="wow slideInLeft" data-wow-delay=".1s">Advertisements & Marketing</h4>
            <div class="heading-border-light"></div>
            <p>A major shift in advertising has come about with the consumption patterns changing in a digital world, where users are on multiple devices and operate in an omnichannel world. Digital has transformed marketing as we know it. Agencies, whose largest constituency was marketing departments, now need to realign themselves to the changing needs of the end consumer and their own Clients to stay relevant and not get relegated to only being the ‘creative’ partner. In fact, even the definition of ‘creative’ is transforming. It is no longer a one to many, one-way advertisement but an omnichannel delivery of a conversation, and engagement and experience. This needs new approaches, new technical skills, and new analytical and data marketing skills than ever before.</p>
            <p>Leverage the opportunities in mobile, cloud and social media. Monitor the buzz about your brand in social networks and marketplaces. React promptly to negative vibes as soon as you find one anywhere in social media. Use our predictive analytics to detect market trends and modify strategies in good time.</p> 
            <p>Get a centrally accessible repository to manage your media and digital assets to drive value for your marketing efforts. Manage your all media content, videos, images, text and audios, from creation to consumption, across the organization and extended enterprise.</p>
            <p>Our marketing automation tools let you drive leads through the sales funnel. Attract socially networked customers to your marketplace.</p>
          </div>
        </div>  
        <div class="col-md-6" >
            <div class="career-p1-himg" >
              <img src="assets/images/industries/Advertising-Digital-Marketing.png" class="img-fluid wow fadeInUp" data-wow-delay="0.1s" alt="">
            </div>
          </div>
      </div>
    </div> 
  </section>  

<!--====================================================
                      CAREER-P2
======================================================-->
  <div class="overlay-career-p2"></div>
  <section id="thought" class="bg-parallax career-p2-bg">
    <div class="container">
      <div id="row" class="row title-bar-career-p2">
        <div class="col-md-4 ">
          <h1 >100%</h1>
        </div>  
        <div class="col-md-8 ">
          <p class="wow fadeInUp " data-wow-delay="0.4s">Delight your audience by delivering an exceptional experience across channels. Find opportunities to engage with customers on social networks. Use rich media to capture business opportunities and deliver personalized marketing messages to promote your brand.</p>
        </div> 
      </div>
    </div>         
  </section> 

<!--====================================================
                     CAREER-P3
======================================================--> 
  <div class="overlay-career-p3"></div>
   <section id="career-p3">
     <div class="container-fluide">
       <div class="row career-p3-title">
        <div class="col-md-12">
         <h3 class="text-center">Our Solutions</h3>
        </div> 
       </div>
     </div>
   </section> 

   <section class="what-we-do bg-gradiant">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-globe"></i>
                <h6>Portals & Mobile Apps</h6>
                <p class="desc">Responsive web portals and apps for campaign management</p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-users"></i>
                <h6>Customer Management</h6>
                <p class="desc"> Get insight on consumer behaviors & expectations. </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-file-invoice-dollar"></i>
                <h6>Catalogue Management</h6>
                <p class="desc">End to end content processing workflow </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-warehouse"></i>
                <h6>Media Management</h6>
                <p class="desc">Complete design and artwork processing and approval workflow. </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-tags"></i>
                <h6>Subscription Management</h6>
                <p class="desc">Comprehensive marketing management of your customers & prospects. </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-comments"></i>
                <h6>Vendor Management</h6>
                <p class="desc">Solutions for managing you vendors for varios media marketing  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>      
  </section> 

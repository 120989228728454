<!--====================================================
                       HOME-P
======================================================-->
<div id="home-p" class="home-p pages-head2 text-center">
    <div class="container">
        <h1 class="wow fadeInUp" data-wow-delay="0.1s">Delivering Transformation to Every Industry</h1>
        <p>Industries We Serve</p>
    </div>
    <!--/end container-->
</div>

<!--====================================================
                      STORY
======================================================-->
<section id="story">
    <div class="container">
        <div class="row title-bar" style="padding: 40px 0px 0px 0px;">
            <div class="col-md-12">
                <h1 class="wow fadeInUp">Our Industry Experience</h1>
                <div class="heading-border"></div>
                <p>If our decades of experience have taught us anything, it’s that if you love what you do, you do it
                    very well. We are incredibly lucky to work with partners in a variety of industries who share our
                    commitment to great work. </p>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                <div class="story-descb"><a routerLink="/retail">
                    <img src="assets/images/industries/retail_icon.jpg" class="img-fluid" alt="...">
                    <h6>Retail & Ecommerce</h6>
                    <i class="fa fa-arrow-circle-o-right"></i> Read More</a>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                <div class="story-descb"> <a routerLink="/realestate">
                    <img src="assets/images/industries/Real-EState-icon.jpg" class="img-fluid" alt="...">
                    <h6>Real Estate</h6>
                   <i class="fa fa-arrow-circle-o-right"></i> Read More</a>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                <div class="story-descb"><a routerLink="/marketing">
                    <img src="assets/images/news/news-8.jpg" class="img-fluid" alt="...">
                    <h6>Advertisements & Marketing</h6>
                    <i class="fa fa-arrow-circle-o-right"></i> Read More</a>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                <div class="story-descb"><a routerLink="/hospitality">
                    <img src="assets/images/industries/Travel_icon.jpg" class="img-fluid" alt="...">
                    <h6>Travel & Hospitality</h6>
                    <i class="fa fa-arrow-circle-o-right"></i> Read More</a>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                <div class="story-descb"><a routerLink="/manufacturing">
                    <img src="assets/images/industries/manufacturing-icon.png" class="img-fluid" alt="...">
                    <h6>Manufacturing</h6>
                    <i class="fa fa-arrow-circle-o-right"></i> Read More</a>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                <div class="story-descb"><a routerLink="/finance">
                    <img src="assets/images/industries/finance_icon.jpg" class="img-fluid" alt="...">
                    <h6>Finance & Banking</h6>
                    <i class="fa fa-arrow-circle-o-right"></i> Read More</a>
                </div>
            </div>
            <div style="padding: 0px 205px; text-align: center;"  >
                <p> If you don’t see your specific industry listed here, don’t worry. We continually seek out opportunities to unleash our potential, designing new and more complete programs for our clients.  </p>
                <button class="btn btn-general btn-green wow fadeInUp" role="button" (click)="openDialog([5])" >Let’s talk.</button>
            </div>
        </div>
    </div>
</section>
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EnquiryComponent } from 'src/app/contactus/enquiry/enquiry.component';

@Component({
  selector: 'app-industries',
  templateUrl: './industries.component.html',
  styleUrls: ['./industries.component.css']
})
export class IndustriesComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openDialog(features:number[] ) {
    const dialogRef = this.dialog.open(EnquiryComponent,{
      data: {options: features},
      panelClass: 'custom-modalbox'
  });
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnquiryComponent } from '../contactus/enquiry/enquiry.component';

declare function TypingText(): any;
declare function fthoughts(): any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    TypingText();
    fthoughts();
  }

  openDialog(features:number[] ) {
    const dialogRef = this.dialog.open(EnquiryComponent,{
      data: {options: features},
      panelClass: 'custom-modalbox'
  });
  }

}

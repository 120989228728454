<!--====================================================
                         HOME
======================================================-->
<section id="home"> 
    <div id="carousel" class="carousel slide carousel-fade" data-ride="carousel"> 
      <!-- Carousel items -->
      <div class="carousel-inner">
          <div class="carousel-item active slides">
            <div class="overlay"></div>
            <div class="slide-1"></div>
              <div class="hero ">
                <div class="wow fadeInUp">
                    <h1>We Help <span ><a href="" class="typewrite" data-period="2000" data-type='[ " Business", " Startup"]'>
                      <span class="wrap"></span></a></span> </h1>        
                    <h3>The next big idea is waiting for its next big changer</h3>
                    </div>
                <button class="btn btn-general btn-green wow fadeInUp" role="button" (click)="openDialog([5])">Contact Now</button>
              </div>           
          </div> 
      </div> 
    </div> 
  </section> 

<!--====================================================
                      ABOUT
======================================================-->
  <section id="about" class="about">
    <div class="container">
      <div class="row title-bar">
        <div class="col-md-12">
          <h1 class="wow fadeInUp">We committed to helping</h1>
          <div class="heading-border"></div>
          <p class="wow fadeInUp" data-wow-delay="0.4s">Your customers have gone online, so your business processes need to resonate with their needs, convenience and expectations.. <br/>At BizTech we offer transforming businesses with powerful and adaptable digital solutions that satisfy the needs of today and unlock the opportunities of tomorrow.</p>
          <div class="title-but"><button class="btn btn-general btn-green" role="button" routerLink="/solutions">Read More</button></div>
        </div>
      </div>
    </div>  
    <!-- About right side withBG parallax -->
    <div class="container-fluid">
      <div class="row"> 
        <div class="col-md-4 bg-starship">
          <div class="about-content-box wow fadeInUp" data-wow-delay="0.3s">
            <i class="fa fa-cogs"></i>
            <h5>Digital Transformation Solutions</h5>
            <p class="desc">Moving from traditional business practises to now into more holistic transformations that clearly are based on mobile, social media, digitization and the power of analytics and we think<br/> it’s really a new era requiring new strategies.</p>
          </div>
        </div> 
        <div class="col-md-4 bg-chathams">
          <div class="about-content-box wow fadeInUp" data-wow-delay="0.5s">
            <i class="fa fa-chart-bar"></i>
            <h5>Data Analytics Services</h5>
            <p class="desc">Data and analytics are invaluable when it comes to decision making for any business. They provide insights whether the business is headed in the right direction or something needs to be done to<br/> steer it on desired track.</p>
          </div>
        </div> 
        <div class="col-md-4 bg-matisse">
          <div class="about-content-box wow fadeInUp" data-wow-delay="0.7s">
            <i class="fas fa-search-dollar"></i>
            <h5>Online Marketing Services</h5>
            <p class="desc">Internet marketing is so important because your customers are online. Considering that over 4.2 billion people are on the Internet,<br/> it’s a great opportunity for you to reach leads that are <br/>looking for your products / services.</p>
          </div>
        </div> 
      </div> 
    </div>       
  </section> 

<!--====================================================
                      OFFER
======================================================-->
  <section id="comp-offer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-3 col-sm-6 desc-comp-offer wow fadeInUp" data-wow-delay="0.2s">
          <h2>What We Offer</h2>
          <div class="heading-border-light"></div> 
          <button class="btn btn-general btn-green" role="button" routerLink="/solutions">See Curren Offers</button>
          <button class="btn btn-general btn-white" role="button" (click)="openDialog([5])">Contact Us Today</button>
        </div>
        <div class="col-md-3 col-sm-6 desc-comp-offer wow fadeInUp" data-wow-delay="0.4s">
          <div class="desc-comp-offer-cont">
            <div class="thumbnail-blogs">
                <div class="caption">
                  <i class="fa fa-chain"></i>
                </div>
                <img src="assets/images/news/news-11.jpg" class="img-fluid" alt="...">
            </div>
            <h3>Business Transformation</h3>
            <p class="desc">We provide the complete end to end service from strategy to implementation for your Digital Transformation journey of the business.   </p>
            <a (click)="openDialog([1])"><i class="fa fa-arrow-circle-o-right"></i> Learn More</a>
          </div>
        </div>          
        <div class="col-md-3 col-sm-6 desc-comp-offer wow fadeInUp" data-wow-delay="0.6s">
          <div class="desc-comp-offer-cont">
            <div class="thumbnail-blogs">
                <div class="caption">
                  <i class="fa fa-chain"></i>
                </div>
                <img src="assets/images/news/news-13.jpg" class="img-fluid" alt="...">
            </div>              
            <h3>Software Development</h3>
            <p class="desc">We can help you to build high-quality software solutions and products as well as deliver a wide range of related professional services. </p>
            <a (click)="openDialog([4])"><i class="fa fa-arrow-circle-o-right"></i> Learn More</a>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 desc-comp-offer wow fadeInUp" data-wow-delay="0.8s">
          <div class="desc-comp-offer-cont">
            <div class="thumbnail-blogs">
                <div class="caption">
                  <i class="fa fa-chain"></i>
                </div>
                <img src="assets/images/news/news-14.jpg" class="img-fluid" alt="...">
            </div>
            <h3>Analytics & Marketing Services</h3>
            <p class="desc">We provide full reporting and insights of your business using our Analytics servcices and drive customised marketing campaigns to grow your Business.</p>
            <a (click)="openDialog([2,3])"><i class="fa fa-arrow-circle-o-right"></i> Learn More</a>
          </div>
        </div>
      </div>
    </div>
  </section>

<!--====================================================
                   WHAT WE DO
======================================================-->
  <section class="what-we-do bg-gradiant">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-3">
          <h3>What we Do</h3>
          <div class="heading-border-light"></div> 
          <p class="desc">We partner with clients to put recommendations into practice. </p>
        </div>
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-globe"></i>
                <h6>Websites & Portals</h6>
                <p class="desc">We build responsive websites and portals for your business</p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-mobile-alt"></i>
                <h6>Mobile Apps</h6>
                <p class="desc">We develop interactive mobile apps to engage your customers  </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-chart-pie"></i>
                <h6>Business Analytics</h6>
                <p class="desc">We analyze your data and provide you with reports and business insights </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-database"></i>
                <h6>Data Management</h6>
                <p class="desc">We provide complete end to end data management platfrom for your business. </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-envelope"></i>
                <h6>Email Campaigns</h6>
                <p class="desc">We execute scheduled / adhoc Email marketing campaigns and drive growth. </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-thumbs-up"></i>
                <h6>Social Marketing</h6>
                <p class="desc">We manage your social media outlook and deliver effective campaigns  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>      
  </section> 

<!--====================================================
                    STORY
======================================================--> 
  <section id="story">
      <div class="container">
        <div class="row title-bar">
          <div class="col-md-12">
            <h1 class="wow fadeInUp">Our Success Tranformation Story</h1>
            <div class="heading-border"></div> 
          </div>
        </div>
      </div>  
      <div class="container-fluid">
        <div class="row" >
          <div class="col-md-6" >
            <div class="story-himg" >
              <img src="assets/images/image-4.jpg" class="img-fluid" alt="">
            </div>
          </div>
          <div class="col-md-6">
            <div class="story-desc">
              <h3>How to grow world with Us</h3>
              <div class="heading-border-light"></div> 
              <p>Everyone defines success differently – as much as there are people, there are different opinions. Number one in our priority list is the success of our clients and their customers. We are proud to be the leading provider of digital transfomration, business data, data analytics, consultative marketing services, and campaign execution solutions with a singular focus on Customer. </p>
              <p>Our business is built on the firm foundation of strong data. It is our holistic approach to data, strategy and insight as well as our follow through with action-based, results-focused execution that uniquely qualifies us to be your growth partner.</p>
              <p class="text-right" style="font-style: italic; font-weight: 700;"><a routerLink="/aboutus">BizTech People</a></p>
              <div class="title-but"><button class="btn btn-general btn-green" role="button" routerLink="/solutions">Read More</button></div>
            </div>
          </div>
        </div>
      </div>  
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.1s"> 
            <div class="story-descb">
                <img src="assets/images/news/news-10.jpg" class="img-fluid" alt="...">
                <h6>Digital Products</h6>
                <p>We provide products to help you adapt to future of digital transformation and that empower your workforce and surpass the <br/>pace of innovation.</p>
                <a routerLink="/digitaltransformation" ><i class="fa fa-arrow-circle-o-right"></i> Read More</a>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.3s"> 
            <div class="story-descb">
                <img src="assets/images/news/news-2.jpg" class="img-fluid" alt="...">
                <h6>Industry Solutions</h6>
                <p>We bring together deep industry expertise and the latest IT advancements to deliver custom solutions that perfectly fit the needs and behavior of your customers.</p>
                <a routerLink="/onlinemarketing"><i class="fa fa-arrow-circle-o-right"></i> Read More</a>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.5s"> 
            <div class="story-descb">
                <img src="assets/images/news/news-8.jpg" class="img-fluid" alt="...">
                <h6>Managed Services</h6>
                <p>We use data, technology, and analytics to help our clients best brands make their advertising more addressable, their experiences more personal, and manage relationships over time.</p>
                <a routerLink="/dataanalytics"><i class="fa fa-arrow-circle-o-right"></i> Read More</a>
            </div>
          </div>                        
        </div>
      </div>  
  </section>

<!--====================================================
                COMPANY THOUGHT
======================================================-->
  <div class="overlay-thought"></div>
  <section id="thought" class="bg-parallax thought-bg">
    <div class="container">
      <div id="thought-desc" class="row title-bar title-bar-thought owl-carousel owl-theme">
        <div class="col-md-12 ">
          <div class="heading-border bg-white"></div>
          <p class="wow fadeInUp" data-wow-delay="0.4s">BizTech will deliver value to all the stakeholders and will attain excellence and leadership through such delivery of value. We will strive to support the stakeholders in all activities related to us. BizTech provide great things.</p>
          <h6>John doe</h6>
        </div>
        <div class="col-md-12 thought-desc">
          <div class="heading-border bg-white"></div>
          <p class="wow fadeInUp" data-wow-delay="0.4s">Ensuring quality in BizTech is an obsession and the high quality standards set by us are achieved through a rigorous quality assurance process. Quality assurance is performed by an independent team of trained experts for each project. </p>
          <h6>Tom John</h6>
        </div>
      </div>
    </div>         
  </section> 
  
<!--====================================================
                 SERVICE-HOME
======================================================--> 
  <section id="service-h" *ngIf="false">
      <div class="container-fluid">
        <div class="row" >
          <div class="col-md-6" >
            <div class="service-himg" > 
              <iframe src="https://www.youtube.com/embed/754f1w90gQU?rel=0&amp;controls=0&amp;showinfo=0" frameborder="0" allowfullscreen></iframe>
            </div>
          </div>
          <div class="col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div class="service-h-desc">
              <h3>We are Providing great Services</h3>
              <div class="heading-border-light"></div> 
              <p>Businessbox offer the full spectrum of services to help organizations work better. Everything from creating standards of excellence to training your people to work in more effective ways.</p>  
            <div class="service-h-tab"> 
              <nav class="nav nav-tabs" id="myTab" role="tablist">
                <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-expanded="true">Developing</a>
                <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile">Training</a> 
                <a class="nav-item nav-link" id="my-profile-tab" data-toggle="tab" href="#my-profile" role="tab" aria-controls="my-profile">Medical</a> 
              </nav>
              <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab"><p>Nulla est ullamco ut irure incididunt nulla Lorem Lorem minim irure officia enim reprehenderit. Magna duis labore cillum sint adipisicing exercitation ipsum. Nostrud ut anim non exercitation velit laboris fugiat cupidatat. Commodo esse dolore fugiat sint velit ullamco magna consequat voluptate minim amet aliquip ipsum aute. exercitation ipsum. Nostrud ut anim non exercitation velit laboris fugiat cupidatat. Commodo esse dolore fugiat sint velit ullamco magna consequat voluptate minim amet aliquip ipsum aute. </p></div>
                <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                  <p>Nulla est ullamco ut irure incididunt nulla Lorem Lorem minim irure officia enim reprehenderit. Magna duis labore cillum sint adipisicing exercitation ipsum. Nostrud ut anim non exercitation velit laboris fugiat cupidatat. Commodo esse dolore fugiat sint velit ullamco magna consequat voluptate minim amet aliquip ipsum aute</p>
                </div> 
                <div class="tab-pane fade" id="my-profile" role="tabpanel" aria-labelledby="my-profile-tab">
                  <p>Nulla est ullamco ut irure incididunt nulla Lorem Lorem minim irure officia enim reprehenderit. Magna duis labore cillum sint adipisicing exercitation ipsum. Nostrud ut anim non exercitation velit laboris fugiat cupidatat. Commodo esse dolore fugiat sint velit ullamco magna consequat voluptate minim amet aliquip ipsum aute</p>
                </div> 
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>  
  </section>

<!--====================================================
                       HOME-P
======================================================-->
<div id="home-p" class="home-p sda-head1 text-center">
    <div class="container">
      <h1 class="wow fadeInUp" data-wow-delay="0.1s">We help you make quick & data-driven decisions.</h1>
      <p>Our Services</p>
    </div><!--/end container-->
  </div> 

<!--====================================================
                    BUSINESS-GROWTH-P1
======================================================-->
<section class="ftco-section">
    <div class="container">  
    <div style="display: flex;flex-flow: row;margin: 30px;">
          <div style="width:301px;margin: 10px;padding-right:20px;" class="img-fluid wow fadeInUp">
              <img width="300px" style="width:300px;height:auto;" src="assets/images/solutions/data-analytics-service.png" alt="Data Analytics">
          </div>
          <div style="width:auto;padding: 20px;text-align: justify;" class="img-fluid wow fadeInRight">
   
  
              <h2 class="wow slideInRight" data-wow-delay=".1s">Data Analytics</h2>
              <div class="heading-border-light"></div>
  
  
  
                <p>Data and analytics are invaluable when it comes to decision making for any business. They provide insights whether the business is headed in the right direction or something needs to be done to steer it on desired track. It helps identify market trends and needs while finding why specific products or services are faring well while others lag behind.</p>
  
  
  
                <p>Such data can help take vital decisions. Including market expansion, widening the range of products or services, pricing policies and customer care, among others. A company that takes decisions based on data and its analysis usually retains an upper hand over competitors. Because it can base its decisions on genuine data rather than guesswork or ambiguous information</p>
  
  
  
                <p>Analytics has come a long way in a relatively short period of time. It can aid in multiple aspects of operations and be a real game-changer for many businesses. But to get maximum results, companies need to know how to properly utilize this technology, improve the quality of their data, and effectively manage it. Those who are able to do so will have a considerable advantage over the competition, and be poised to succeed in 2020 and beyond.</p>
  
  
  
      </div>
    </div>
  <div >
      <!--====================================================
                      BUSINESS-GROWTH-P1
======================================================-->
<section id="business-growth-p1" class="business-growth-p1 ">
    <div class="container">
      <div class="row title-bar" style="padding: 0px 0px 0px 0px;">
        <div class="col-md-12">
          <h1 class="wow fadeInUp">We committed to helping</h1>
          <div class="heading-border"></div>
          <p class="wow fadeInUp" data-wow-delay="0.4s">We help businesses convert their raw business data into actionable insights. Biztech has been helping companies make quick and data-driven decisions in the ever-changing environment by rendering a full set of data analytics services.<br/>
            Call us today! Unlock opportunities for your enterprise in the data-driven business world!
          </p>
        </div>
      </div>
      <div class="row wow animated fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
        <div class="col-md-3 col-sm-6 service-padding">
            <div class="service-item">
                <div class="service-item-icon"> <i class="fa fa-cogs fa-3x"></i>
                </div>
                <div class="service-item-title">
                    <h3>Data Integration</h3>
                </div>
                <div class="service-item-desc">
                    <p>Integrate data from multiple sources for end user reports and analysis.</p> 
                    <div class="content-title-underline-light"></div> 
                </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 service-padding">
            <div class="service-item">
                <div class="service-item-icon"> <i class="fa fa-tasks fa-3x"></i>
                </div>
                <div class="service-item-title">
                    <h3>Data Quality</h3>
                </div>
                <div class="service-item-desc">
                    <p>Build Reliable and Accurate Analytics Capabilities with Data Quality</p>
                    <div class="content-title-underline-light"></div> 
                </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 service-padding">
            <div class="service-item">
                <div class="service-item-icon"> <i class="fa fa-eye fa-3x"></i>
                </div>
                <div class="service-item-title">
                    <h3>BI & Analytics</h3>
                </div>
                <div class="service-item-desc">
                    <p>Improve detailed insights of your business for informed decision-making</p>
                    <div class="content-title-underline-light"></div> 
                </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 service-padding">
            <div class="service-item right-bord">
                <div class="service-item-icon"> <i class="fa fa-map-signs fa-3x"></i>
                </div>
                <div class="service-item-title">
                    <h3>Predictive Intelligence</h3>
                </div>
                <div class="service-item-desc">
                    <p>Drive business based on analysis of historic data to make predictions of future.</p>
                    <div class="content-title-underline-light"></div> 
                </div>
            </div>
        </div> 
      </div>
    </div>  
  </section>   
  </div>
  </div>
  </section>
<!--====================================================
                       HOME-P
======================================================-->
<div id="home-p" class="home-p pages-head3 text-center">
    <div class="container">
        <h1 class="wow fadeInUp" data-wow-delay="0.1s">Reimagine your business transformed</h1>
        <p>Our Solutions</p>
    </div>
    <!--/end container-->
</div>

<!--====================================================
                      STORY
======================================================-->
<section id="story">
    <div class="container">
        <div class="row title-bar" style="padding: 40px 0px 0px 0px;">
            <div class="col-md-12">
                <h1 class="wow fadeInUp">Our Digital transformation enablers</h1>
                <div class="heading-border"></div>
                <p>We help clients redefine customer experience, explore new operating models, create enhanced digital channels, and provide solutions required to compete in today’s marketplace.
                    Call us today! Reshape, Reinvent and Stand out as the leader in the digital business world!. </p>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                <div class="story-descb"><a routerLink="/digitaltransformation">
                    <img src="assets/images/solutions/Digital-transformation_icon.jpeg" class="img-fluid" alt="...">
                    <h6>Digital Transformation</h6>
                    <i class="fa fa-arrow-circle-o-right"></i> Read More</a>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                <div class="story-descb"> <a routerLink="/dataanalytics">
                    <img src="assets/images/solutions/Data-Analytics_icon.png" class="img-fluid" alt="...">
                    <h6>Data Analytics</h6>
                   <i class="fa fa-arrow-circle-o-right"></i> Read More</a>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                <div class="story-descb"><a routerLink="/onlinemarketing">
                    <img src="assets/images/solutions/digital-marketing_icon.jpg" class="img-fluid" alt="...">
                    <h6>Online Marketing</h6>
                    <i class="fa fa-arrow-circle-o-right"></i> Read More</a>
                </div>
            </div>
            <div style="padding: 0px 205px; text-align: center;"  >
                <p> If you don’t see your specific solution listed here, don’t worry. We continually seek out opportunities to unleash our potential, designing new and more complete programs for our clients.  </p>
                <button class="btn btn-general btn-green wow fadeInUp" role="button" (click)="openDialog([5])" >Let’s talk.</button>
            </div>
        </div>
    </div>
</section>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  // apiUrl = "http://api.yashtec.com/api/contact/";
  apiUrl = "https://glvd3gvhe5.execute-api.us-east-1.amazonaws.com/Prod/submitform";

  emlcnt = {'name':'<name>', 'email':'<email>','bodyText':'<bodytext'};

  constructor(private _http: HttpClient) {}

  contactRequest(creq: string):Observable<any> {
    this.emlcnt.name = 'BTPcontactus';
    this.emlcnt.email = 'contact@biztechpeople.com';
    this.emlcnt.bodyText = creq;
    return this._http.post( (this.apiUrl), this.emlcnt);
  }

  enquireRequest(cenq: string):Observable<any> {
    this.emlcnt.name = 'BTPenquireRequest';
    this.emlcnt.email = 'enquiry@biztechpeople.com';
    this.emlcnt.bodyText = cenq;
    return this._http.post( (this.apiUrl), this.emlcnt);
  }

}

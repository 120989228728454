import { Component, OnInit } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { ContactService } from '../services/contact.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  public confirmation = false;
  data: any = {};

  constructor(private _contactservice: ContactService) { }

  ngOnInit(): void {
  }

  
  onSubmit() {
    this._contactservice.contactRequest(this.data).subscribe(data =>{console.log(data); },
    error => console.log(error),
    () => {console.log('Contact Request Submitted'); this.confirmation = true; });
    //alert(JSON.stringify(this.data));
  }
  


  getErrorMsg(ftype: string, err: ValidationErrors ){
    var msg: string = "";
    
    switch(ftype){
        case "cname":{
          if(err.required){
            msg = "Name is required!";
          }
          else
          {
            msg = "Name is Invalid!";
          }
          break;
        }
        case "cemail":{
          if(err.required){
          msg = "Email is required!"
          }
          else
          {
            msg = "Email address is Invalid!";
          }
          break;
        }

    }
    return msg;
}

}

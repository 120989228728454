import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { HomeComponent } from './home/home.component';
import { AboutusComponent } from './company/aboutus/aboutus.component';
import { OurteamComponent } from './company/ourteam/ourteam.component';
import { SolutionsComponent } from './solutions/solutions/solutions.component';
import { DataanalyticsComponent } from './solutions/dataanalytics/dataanalytics.component';
import { DigitaltransformationComponent } from './solutions/digitaltransformation/digitaltransformation.component';
import { OnlinemarketingComponent } from './solutions/onlinemarketing/onlinemarketing.component';
import { ContactusComponent } from './contactus/contactus.component';
import { IndustriesComponent } from './industries/industries/industries.component';
import { RetailComponent } from './industries/retail/retail.component';
import { FinanceComponent } from './industries/finance/finance.component';
import { HospitalityComponent } from './industries/hospitality/hospitality.component';
import { ManufacturingComponent } from './industries/manufacturing/manufacturing.component';
import { MarketingComponent } from './industries/marketing/marketing.component';
import { RealestateComponent } from './industries/realestate/realestate.component';

const routes: Routes = [ 
  { path:'',    component:HomeComponent},
  { path:'home',    component:HomeComponent},

  { path:'aboutus',    component:AboutusComponent},
  { path:'ourteam',    component:OurteamComponent},

  { path:'solutions',    component:SolutionsComponent},
  { path:'digitaltransformation',    component:DigitaltransformationComponent},
  { path:'dataanalytics',    component:DataanalyticsComponent},
  { path:'onlinemarketing',    component:OnlinemarketingComponent},

  { path:'industries',    component:IndustriesComponent},
  { path:'finance',    component:FinanceComponent},
  { path:'hospitality',    component:HospitalityComponent},
  { path:'manufacturing',    component:ManufacturingComponent},
  { path:'marketing',    component:MarketingComponent},
  { path:'realestate',    component:RealestateComponent},
  { path:'retail',    component:RetailComponent},

  { path:'contactus',    component:ContactusComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' ,scrollPositionRestoration: 'enabled',useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

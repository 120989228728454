<!--====================================================
                       HOME-P
======================================================-->
<div id="home-p" class="home-p pages-head2 text-center">
    <div class="container">
      <h1 class="wow fadeInUp" data-wow-delay="0.1s">We understand your industry</h1>
      <p>Manufacturing</p>
    </div><!--/end container-->
  </div> 

<!--====================================================
                      CAREER-P1
======================================================-->

  <section id="about" class="career-p1 about">
    <div class="container">
      <div class="row"> 

        <div class="col-md-6 img-fluid wow fadeInLeft">
          <div class="career-p1-desc">
            <h4 class="wow slideInLeft" data-wow-delay=".1s">Manufacturing</h4>
            <div class="heading-border-light"></div>
            <p>Manufacturing companies striving to reach a global status need to be thinking about how to improve productivity not just on the factory floor, but throughout their entire supply chain. Technological change will have an impact on every aspect of design and process, from customer choice to supply chain automation. It is essential for the future of the manufacturing sector that all companies, regardless of their size or sub-sector, do not underestimate the challenges that come with digital transformation, but embrace the productivity and strategic advantages it bring</p>
            <p>We at BizTech believe in overcoming business challenges with the help of technology and accurate procedure powered by agility, speed, and innovation.</p> 
            <p>In the progressively developing digital world, manufacturing industries must implement and execute centralized software solutions to neutralize the feasible threats within the industry.</p>
            <p>BizTech is committed to bringing in functional excellence, agility, and innovation through its state-of-the-art software solution. It is truly an effective tool to reduce the cost and increase brand reputation in the industry.</p>
            <p>With our agile IT solutions for the manufacturing industry, you can eliminate the reliance on third-party vendors for data and other services. We understand your industry and know how to optimize the operational procedure in your company.</p>
          </div>
        </div>  
        <div class="col-md-6" >
            <div class="career-p1-himg" >
              <img src="assets/images/industries/Manufacturing_industry.jpg" class="img-fluid wow fadeInUp" data-wow-delay="0.1s" alt="">
            </div>
          </div>
      </div>
    </div> 
  </section>  

<!--====================================================
                      CAREER-P2
======================================================-->
  <div class="overlay-career-p2"></div>
  <section id="thought" class="bg-parallax career-p2-bg">
    <div class="container">
      <div id="row" class="row title-bar-career-p2">
        <div class="col-md-4 ">
          <h1 >100%</h1>
        </div>  
        <div class="col-md-8 ">
          <p class="wow fadeInUp " data-wow-delay="0.4s">We have a comprehensive portfolio of services solutions that empower you to meet your business requirements and attain operational agility, excellence and cost competence. Our services include ERP implementation, infrastructure management, application development, and others.</p>
        </div> 
      </div>
    </div>         
  </section> 

<!--====================================================
                     CAREER-P3
======================================================--> 
  <div class="overlay-career-p3"></div>
   <section id="career-p3">
     <div class="container-fluide">
       <div class="row career-p3-title">
        <div class="col-md-12">
         <h3 class="text-center">Our Solutions</h3>
        </div> 
       </div>
     </div>
   </section> 

   <section class="what-we-do bg-gradiant">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-globe"></i>
                <h6>Portals & Mobile Apps</h6>
                <p class="desc">Responsive web portal and apps for optimized solutions</p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-users"></i>
                <h6>Customer Management</h6>
                <p class="desc"> Get insight on consumer behaviors & expectations. </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-file-invoice-dollar"></i>
                <h6>Order Management</h6>
                <p class="desc">End to end Order processing workflow </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-warehouse"></i>
                <h6>Warehouse Management</h6>
                <p class="desc">Complete site and raw / finished goods management for your Inventory. </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
                <div class="what-we-desc">
                  <i class="fa fa-comments"></i>
                  <h6>Resource Planning</h6>
                  <p class="desc">Solutions for complete manufacturing process planning  </p>
                </div>
              </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-tags"></i>
                <h6>Price and Promotions</h6>
                <p class="desc">Effective prices management as wells as promotion exeuction for sales. </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>      
  </section> 

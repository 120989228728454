<!--====================================================
                         HEADER
======================================================--> 
<header>

    <!-- Top Navbar  -->
    <div class="top-menubar">
      <div class="topmenu">
        <div class="container">
          <div class="row">
            <div class="col-md-7">
              <ul class="list-inline top-contacts">
                <li>
                  <i class="fa fa-envelope"></i> Email: <a href="mailto:info@biztechpeople.com">info@biztechpeople.com</a>
                </li>
                <li>
                  <i class="fa fa-phone"></i> Phone: (+1) 732-470 0007
                </li>
              </ul>
            </div> 
            <div class="col-md-5">
              <ul class="list-inline top-data">
                <li><a href="#" target="_empty"><i class="fa top-social fa-facebook"></i></a></li>
                <li><a href="#" target="_empty"><i class="fa top-social fa-twitter"></i></a></li>
                <li><a href="#" target="_empty"><i class="fa top-social fa-google-plus"></i></a></li> 
                <li><a href="#" class="log-top" data-toggle="modal" data-target="#login-modal">Login</a></li>  
              </ul>
            </div>
          </div>
        </div>
      </div> 
    </div> 
    
    <!-- Navbar -->
    <nav class="navbar navbar-expand-lg navbar-light" id="mainNav" data-toggle="affix">
      <div class="container">
        <a class="navbar-brand smooth-scroll" routerLink="/">
          <img src="assets/images/logo-s.png" style="width: 300px;height: auto;margin: -5px 0px;" alt="logo">
        </a> 
        <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"> 
              <span class="navbar-toggler-icon"></span>
        </button>  
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ml-auto">
              <li class="nav-item" ><a class="nav-link smooth-scroll" routerLink="/" >Home</a></li>

              <li class="nav-item dropdown" >
                  <a class="nav-link dropdown-toggle smooth-scroll" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="#">Solutions</a>
                  <div class="dropdown-menu dropdown-cust" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item" routerLink="/digitaltransformation">Digital Transformation</a>
                    <a class="dropdown-item" routerLink="/dataanalytics">Data Analytics</a>
                    <a class="dropdown-item" routerLink="/onlinemarketing">Online Marketing</a>
                  </div>
              </li> 
              <li class="nav-item dropdown" >
                <a class="nav-link dropdown-toggle smooth-scroll" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Industries</a> 
                <div class="dropdown-menu dropdown-cust" aria-labelledby="navbarDropdownMenuLink"> 
                  <a class="dropdown-item"  routerLink="/retail">Retail & Ecommerce</a> 
                  <a class="dropdown-item"  routerLink="/realestate">Real Estate</a> 
                  <a class="dropdown-item"  routerLink="/marketing">Advertisements & Marketing</a> 
                  <a class="dropdown-item"  routerLink="/hospitality">Travel & Hospitality</a> 
                  <a class="dropdown-item"  routerLink="/manufacturing">Manufacturing</a> 
                  <a class="dropdown-item"  routerLink="/finance">Finance & Banking</a> 

                </div>
              </li>
              <li class="nav-item dropdown" >
                <a class="nav-link dropdown-toggle smooth-scroll" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Company</a> 
                <div class="dropdown-menu dropdown-cust" aria-labelledby="navbarDropdownMenuLink">
                  <a class="dropdown-item" routerLink="/aboutus">About Us</a>
                  <a class="dropdown-item" routerLink="/ourteam">Meet Our Team</a>
                </div>
              </li>
              <li class="nav-item" ><a class="nav-link smooth-scroll" routerLink="/contactus" >Contact</a></li>
              <li>
                <i class="search fa fa-search search-btn"></i>
                <div class="search-open">
                  <div class="input-group animated fadeInUp">
                    <input type="text" class="form-control" placeholder="Search" aria-describedby="basic-addon2">
                    <span class="input-group-addon" id="basic-addon2">Go</span>
                  </div>
                </div>
              </li> 
              <li>
                <div class="top-menubar-nav">
                  <div class="topmenu ">
                    <div class="container">
                      <div class="row">
                        <div class="col-md-9">
                          <ul class="list-inline top-contacts">
                            <li>
                              <i class="fa fa-envelope"></i> Email: <a href="mailto:info@biztechpeople.com">info@biztechpeople.com</a>
                            </li>
                            <li>
                              <i class="fa fa-phone"></i> Phone: (+1) 732-470 0007
                            </li>
                          </ul>
                        </div> 
                        <div class="col-md-3">
                          <ul class="list-inline top-data">
                            <li><a href="#" target="_empty"><i class="fa top-social fa-facebook"></i></a></li>
                            <li><a href="#" target="_empty"><i class="fa top-social fa-twitter"></i></a></li>
                            <li><a href="#" target="_empty"><i class="fa top-social fa-google-plus"></i></a></li> 
                            <li><a href="#" class="log-top" data-toggle="modal" data-target="#login-modal">Login</a></li>  
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div> 
                </div>
              </li>
          </ul>  
        </div>
      </div>
    </nav>
  </header> 

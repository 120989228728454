<h2 mat-dialog-title class="dhead" fxLayoutAlign=" center" > <mat-icon >{{ !confirmation ? "help" : "check_circle"}}</mat-icon> <span style="padding-left: 5px;">{{ !confirmation ? "How can we help?" :"Thank you"}} </span></h2>
<div class="mainbody">
    <div fxLayout="column" fxFlex="100" *ngIf="confirmation">

        <h2>Request Submitted</h2>
        <p>Thanks for reaching out, we're on it! And we'll respond as soon as possible</p>
        <p>Our representiative will reachout shortly on:<br>
          Email: <span style="color: navy;" >{{this.editForm.value.emailID}}</span> <br>
          Phone: <span style="color: navy;" >{{this.editForm.value.phone }}</span>
        </p>
        <p>
          Meanwhile, please visit our website section below for more details:<br><br>
          <mat-nav-list>
            <span style="align-items: center;"><a routerLink='/home'>
                <mat-icon>home</mat-icon> Home Page
              </a></span> <br>
            <span style="align-self: center ;"><a routerLink='/retail'>
                <mat-icon>live_help</mat-icon> Industries
              </a></span><br>
            <span style="vertical-align: middle ;"><a routerLink='/digitaltransformation'>
                <mat-icon>toys</mat-icon> Solutions & Services
              </a></span><br>
            <span style="vertical-align: middle ;"><a routerLink='/aboutus'>
                <mat-icon>face</mat-icon> About Us
              </a></span>
          </mat-nav-list>
        </p>
      </div>
      <div *ngIf="!confirmation">
    <form novalidate [formGroup]="editForm">
        <div fxLayout="row" >
            <div fxLayout="column" fxFlex="49">
                <mat-form-field class="example-full-width">
                    <input matInput placeholder="First Name*" minlength="4" formControlName="firstName" maxlength="25">
                    <mat-error *ngIf="(editForm.controls.firstName.touched || editForm.controls.firstName.dirty) && editForm.controls.firstName.hasError('required')">
                        First name is <strong>required</strong>
                   </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="column" fxFlex="2"></div>
        <div fxLayout="column" fxFlex="49">
            <mat-form-field class="example-full-width">
                <input matInput placeholder="Last Name*" formControlName="lastName" maxlength="50">
                <mat-error *ngIf="(editForm.controls.lastName.touched || editForm.controls.lastName.dirty) && editForm.controls.lastName.hasError('required')">
                    Last name is <strong>required</strong>
               </mat-error>

            </mat-form-field>

        </div>


        </div>
        <div fxLayout="row" >
        <div fxLayout="column" fxFlex="100">
            <mat-form-field class="example-full-width">
                <input matInput placeholder="Company Name"  formControlName="companyName"  maxlength="4000">
                <mat-icon matSuffix>store</mat-icon>
            </mat-form-field>
        </div>
        </div>
        <div fxLayout="row" >
            <div fxLayout="column" fxFlex="49">
                <mat-form-field class="example-full-width">
                    <input matInput placeholder="Phone*" formControlName="phone" prefix="+1"  maxlength="25" ><mat-icon matSuffix>phone</mat-icon>
                    <mat-error *ngIf="(editForm.controls.phone.touched || editForm.controls.phone.dirty) && editForm.controls.phone.hasError('required')">
                        Phone is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="column" fxFlex="2"></div>
            <div fxLayout="column" fxFlex="49">
                <mat-form-field class="example-full-width">
                    <input matInput placeholder="Email*" formControlName="emailID" maxlength="100">
                    <mat-icon matSuffix>email</mat-icon>
                    <mat-error *ngIf=" (editForm.controls.emailID.touched || editForm.controls.emailID.dirty) && editForm.controls.emailID.hasError('email') && !editForm.controls.emailID.hasError('required')">
                    Please enter a valid email address
                    </mat-error>
                    <mat-error *ngIf="(editForm.controls.emailID.touched || editForm.controls.emailID.dirty) && editForm.controls.emailID.hasError('required')">
                      Email is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            </div>
        <div fxLayout="row" >
            <div fxLayout="column" fxFlex="49">
                <mat-form-field class="example-full-width">
                    <input matInput placeholder="Website" formControlName="webURL" maxlength="50">
                    <mat-icon matSuffix>web</mat-icon>
                </mat-form-field>
            </div>
            <div fxLayout="column" fxFlex="2"></div>
        <div fxLayout="column" fxFlex="49">
            <mat-form-field>
            <mat-label>Country</mat-label>
                <mat-select  formControlName="country" required>
                    <mat-option value="USA">USA</mat-option>
                    <mat-option value="India">India</mat-option>
                    <mat-option value="UK">UK</mat-option>
                    <mat-option value="China">China</mat-option>
                    <mat-option value="Japan">Japan</mat-option>
                    <mat-option value="Austraia">Austraia</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.controls.country.hasError('required')">Please choose a Country</mat-error>
            </mat-form-field>
        </div>
        </div>
        <div fxLayout="row" >
        <div fxLayout="column" fxFlex="49">
        <mat-form-field>
            <mat-label>Industry</mat-label>
                <mat-select  formControlName="industry" required>
					<mat-option value="Advertising &amp; Marketing">Advertising &amp; Marketing</mat-option>
					<mat-option value="Analytics">Analytics</mat-option>
					<mat-option value="Banking">Banking</mat-option>
					<mat-option value="Construction">Construction</mat-option>
					<mat-option value="Customer Service">Customer Service</mat-option>
					<mat-option value="eCommerce">eCommerce</mat-option>
					<mat-option value="Education">Education</mat-option>
					<mat-option value="Energy">Energy</mat-option>
					<mat-option value="Environmental">Environmental</mat-option>
					<mat-option value="Event Management">Event Management</mat-option>
					<mat-option value="Financial Services">Financial Services</mat-option>
					<mat-option value="Fleet Management">Fleet Management</mat-option>
					<mat-option value="Food &amp; Beverage">Food &amp; Beverage</mat-option>
					<mat-option value="Governance, Risk &amp; Compliance">Governance, Risk &amp; Compliance</mat-option>
					<mat-option value="Government/Public Sector">Government/Public Sector</mat-option>
					<mat-option value="Healthcare">Healthcare</mat-option>
					<mat-option value="Hospitality">Hospitality</mat-option>
					<mat-option value="Human Resources/Staffing/Benefits">Human Resources/Staffing/Benefits</mat-option>
					<mat-option value="Insurance">Insurance</mat-option>
					<mat-option value="IT Infrastructure &amp; Management">IT Infrastructure &amp; Management</mat-option>
					<mat-option value="Law Enforcement &amp; Corrections">Law Enforcement &amp; Corrections</mat-option>
					<mat-option value="Legal">Legal</mat-option>
					<mat-option value="Manufacturing">Manufacturing</mat-option>
					<mat-option value="Media">Media</mat-option>
					<mat-option value="Not for Profit">Not for Profit</mat-option>
					<mat-option value="Private Equity/Venture Capital">Private Equity/Venture Capital</mat-option>
					<mat-option value="Procurement">Procurement</mat-option>
					<mat-option value="Professional Services">Professional Services</mat-option>
					<mat-option value="Public Sector">Public Sector</mat-option>
					<mat-option value="Real Estate">Real Estate</mat-option>
					<mat-option value="Recreation &amp; Fitness">Recreation &amp; Fitness</mat-option>
					<mat-option value="Reservations">Reservations</mat-option>
					<mat-option value="Retail">Retail</mat-option>
					<mat-option value="Sales">Sales</mat-option>
					<mat-option value="Security">Security</mat-option>
					<mat-option value="Science/Labs">Science/Labs</mat-option>
					<mat-option value="Shipping">Shipping</mat-option>
					<mat-option value="Sports">Sports</mat-option>
					<mat-option value="Software Development">Software Development</mat-option>
					<mat-option value="Supply Chain &amp; Logistics">Supply Chain &amp; Logistics</mat-option>
					<mat-option value="Telecommunications">Telecommunications</mat-option>
					<mat-option value="Transportation">Transportation</mat-option>
					<mat-option value="Travel &amp; Expense">Travel &amp; Expense</mat-option>
					<mat-option value="Utilities">Utilities</mat-option>
					<mat-option value="Value Added Reseller">Value Added Reseller</mat-option>
					<mat-option value="Other">Other</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.controls.industry.hasError('required')">Please choose an Industry</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="column" fxFlex="2"></div>
        <div fxLayout="column" fxFlex="49">
            <mat-form-field>
            <mat-label>Get in Touch </mat-label>
                <mat-select  formControlName="contactby"  required>
                    <mat-option value="Earliest">At the Earliest</mat-option>
                    <mat-option value="Week">Within a Week</mat-option>
                    <mat-option value="Month">Within a Month</mat-option>
                    <mat-option value="3 Months">in 3 Month</mat-option>
                    <mat-option value="Later">Later</mat-option>
                </mat-select>
                <mat-error *ngIf="editForm.controls.contactby.hasError('required')">Please choose valid duration</mat-error>
            </mat-form-field>
        </div>
        </div>
        <div fxLayout="row" style="padding: 10px 0px;">
            <div fxLayout="column" fxFlex="100"> <span><Strong>Solutions of interest </Strong> <span style="font-size: smaller;color: gray;">Select all that apply</span></span></div>
        </div>
        <div fxLayout="row" >
            <div fxLayout="column"  fxFlex="49"> <mat-checkbox formControlName="interestDT" color="primary" >Digital Transformation</mat-checkbox> </div>
            <div fxLayout="column" fxFlex="2"></div>
            <div fxLayout="column" fxFlex="49"><mat-checkbox formControlName="interestDA" color="primary">Data Analytics</mat-checkbox></div>
        </div>
        <div fxLayout="row" >
            <div fxLayout="column" fxFlex="49"> <mat-checkbox  formControlName="interestOM" color="primary">Online Marketing</mat-checkbox> </div>
            <div fxLayout="column" fxFlex="2"></div>
            <div fxLayout="column" fxFlex="49"><mat-checkbox  formControlName="interestCS" color="primary">Custom Solution</mat-checkbox></div>
        </div>
        <div fxLayout="row" >
            <div fxLayout="column" fxFlex="100">
                <mat-form-field class="example-full-width">
                    <textarea matInput formControlName="comments" placeholder="Tell us more about your project...." miltiline="true" rows="5" ></textarea>
                </mat-form-field>
            </div>
            </div>
            <div fxLayout="row" style="padding: 10px 0px;">
                <div fxLayout="column" fxFlex="100"> <span style="font-size: smaller;" >Would you like to receive news and updates of our offerings?</span></div>
            </div>
            <div fxLayout="row" >
                <div fxLayout="column" fxFlex="100"> <mat-checkbox  formControlName="subscribe"  checked="true" style="font-size: smaller;"  color="primary">Yes, I would like to hear about your services</mat-checkbox> </div>
            </div>
    </form>
    </div>
</div>
<mat-divider></mat-divider>
<mat-dialog-actions align="end" class="dfoot">
    <button mat-button mat-dialog-close *ngIf="confirmation">Close</button>
    <button mat-button mat-dialog-close *ngIf="!confirmation">Cancel</button>
    <button mat-raised-button *ngIf="!confirmation" color="primary" [disabled] ="!editForm.valid" (click)="sendEnquiry()"  cdkFocusInitial>Send</button>
  </mat-dialog-actions>

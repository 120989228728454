<!--====================================================
                       HOME-P
======================================================-->
<div id="home-p" class="home-p pages-head2 text-center">
    <div class="container">
      <h1 class="wow fadeInUp" data-wow-delay="0.1s">We understand your industry</h1>
      <p>Retail & Ecommerce</p>
    </div><!--/end container-->
  </div> 

<!--====================================================
                      CAREER-P1
======================================================-->

  <section id="about" class="career-p1 about">
    <div class="container">
      <div class="row"> 

        <div class="col-md-6 img-fluid wow fadeInLeft">
          <div class="career-p1-desc">
            <h4 class="wow slideInLeft" data-wow-delay=".1s">Retail & Ecommerce</h4>
            <div class="heading-border-light"></div>
            <p>Customers are choosing, sharing, and buying at all times. Increasingly, you need a business optimization platform that understands an omnichannel retail sales model. Systems that connects to all your data for proactive insights and smarter decisions. From campaign profitability to smooth fulfillment, you need to know what’s happening, right as it’s happening.</p>
            <p>With the rapid shifts in consumer demands and loyalty, retailers have to run their operations efficiently and augment capabilities to get new customers and retain the existing ones. Improve traffic, increase conversions and reduce operational costs with our multi-channel extension, mobility and advance e-commerce analytics solutions.</p> 
            <p>Leverage on focused customer behavior data and crowd wisdom to identify cross-selling opportunities. Listen to the online buzz about your company and business to get insight on consumer behaviors and expectations. Deliver targeted, customized campaigns for unique user experience and increment ROI with omnichannel digital transformations of your deliverables.</p>
            <p>Get the target audience to your retails and convert them to being on the right side of technology and marketing intelligence.</p>
          </div>
        </div>  
        <div class="col-md-6" >
            <div class="career-p1-himg" >
              <img src="assets/images/industries/retail_ecommerce_industry.jpg" class="img-fluid wow fadeInUp" data-wow-delay="0.1s" alt="">
            </div>
          </div>
      </div>
    </div> 
  </section>  

<!--====================================================
                      CAREER-P2
======================================================-->
  <div class="overlay-career-p2"></div>
  <section id="thought" class="bg-parallax career-p2-bg">
    <div class="container">
      <div id="row" class="row title-bar-career-p2">
        <div class="col-md-4 ">
          <h1 >100%</h1>
        </div>  
        <div class="col-md-8 ">
          <p class="wow fadeInUp " data-wow-delay="0.4s">When a retailer’s greatest asset is getting new ideas to consumers quickly, companies can’t afford to let technology slow them down. Instead, it must help address key industry issues. Here are some key areas that BizTech helps its customers address every day.</p>
        </div> 
      </div>
    </div>         
  </section> 

<!--====================================================
                     CAREER-P3
======================================================--> 
  <div class="overlay-career-p3"></div>
   <section id="career-p3">
     <div class="container-fluide">
       <div class="row career-p3-title">
        <div class="col-md-12">
         <h3 class="text-center">Our Solutions</h3>
        </div> 
       </div>
     </div>
   </section> 

   <section class="what-we-do bg-gradiant">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-globe"></i>
                <h6>Webstores & Mobile Apps</h6>
                <p class="desc">Responsive websites and apps for online sales</p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-users"></i>
                <h6>Customer Management</h6>
                <p class="desc"> Get insight on consumer behaviors & expectations. </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-file-invoice-dollar"></i>
                <h6>Order Management</h6>
                <p class="desc">End to end Order processing workflow </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-warehouse"></i>
                <h6>Inventory Management</h6>
                <p class="desc">Complete Inventory management for your products. </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-tags"></i>
                <h6>Price and Promotions</h6>
                <p class="desc">Effective prices management as wells as promotion exeuction for sales. </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-comments"></i>
                <h6>Reviews and Feedback</h6>
                <p class="desc">Solutions for customer reviews and Feedback  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>      
  </section> 

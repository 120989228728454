<!--====================================================
                      FOOTER
======================================================--> 
<footer> 
    <div id="footer-s1" class="footer-s1">
      <div class="footer">
        <div class="container">
          <div class="row">
            <!-- About Us -->
            <div class="col-md-3 col-sm-6 ">
              <div><img src="assets/images/logo-w.png" alt="" class="img-fluid"></div>
              <ul class="list-unstyled comp-desc-f">
                 <li><p>We are proud to have worked with a wide variety of leading clients from many different industries and disciplines.

                  You can learn more about our work by visitng our experience pages. </p></li> 
              </ul><br> 
            </div>
            <!-- End About Us -->

            <!-- Recent News -->
            <div class="col-md-3 col-sm-6 ">
              <div class="heading-footer"><h2>Useful Links</h2></div>
              <ul class="list-unstyled link-list">
                <li><a routerLink="/aboutus">About us</a><i class="fa fa-angle-right"></i></li>
                <li><a routerLink="/solutions">Our Solutions</a><i class="fa fa-angle-right"></i></li>  
                <li><a routerLink="/industries">We Serve</a><i class="fa fa-angle-right"></i></li> 
                <li><a routerLink="/ourteam">Our Team</a><i class="fa fa-angle-right"></i></li> 
                <li><a routerLink="/contactus">Contact us</a><i class="fa fa-angle-right"></i></li> 
              </ul>
            </div>
            <!-- End Recent list -->

            <!-- Recent Blog Entries -->
            <div class="col-md-3 col-sm-6 ">
              <div class="heading-footer"><h2>Recent Post Entries</h2></div>
              <ul class="list-unstyled thumb-list">
                <li>
                  <div class="overflow-h">
                    <a href="#">Praesent ut consectetur diam.</a>
                    <small>02 OCT, 2017</small>
                  </div>
                </li>
                <li>
                  <div class="overflow-h">
                    <a href="#">Maecenas pharetra tellus et fringilla.</a>
                    <small>02 OCT, 2017</small>
                  </div>
                </li>
              </ul>
            </div>
            <!-- End Recent Blog Entries -->

            <!-- Latest Tweets -->
            <div class="col-md-3 col-sm-6">
              <div class="heading-footer"><h2>Get In Touch</h2></div>
              <address class="address-details-f">
                10, Labrador Drive, Kendall Park <br>
                New Jersey, US <br>
                Phone: +1 (732) 470-0007 <br>
                Fax: +1 (732) 325-3046 <br>
                Email: <a href="mailto:info@biztechpeople.com" class="">info@biztechpeople.com</a>
              </address>  
              <ul class="list-inline social-icon-f top-data">
                <li><a href="#" target="_empty"><i class="fa top-social fa-facebook"></i></a></li>
                <li><a href="#" target="_empty"><i class="fa top-social fa-twitter"></i></a></li>
                <li><a href="#" target="_empty"><i class="fa top-social fa-google-plus"></i></a></li> 
              </ul>
            </div>
            <!-- End Latest Tweets -->
          </div>
        </div><!--/container -->
      </div> 
    </div>

    <div id="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div id="footer-copyrights">
                        <p>Copyrights &copy; 2017 All Rights Reserved by BizTech People. <a href="#">Privacy Policy</a> <a href="#">Terms of Services</a></p>
                    </div>
                </div> 
            </div>
        </div>
    </div>
    <a href="javascript:window.scroll({top: 0, left: 0, behavior: 'smooth' });" id="back-to-top" class="btn btn-sm btn-green btn-back-to-top smooth-scrolls hidden-sm hidden-xs" title="home" role="button">
        <i class="fa fa-angle-up"></i>
    </a>
</footer>

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import{ FormsModule,ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http'
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule} from '@angular/material/dialog';
import { MatDividerModule} from '@angular/material/divider';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WebappheaderComponent } from './shared/webappheader/webappheader.component';
import { WebappfooterComponent } from './shared/webappfooter/webappfooter.component';
import { HomeComponent } from './home/home.component';
import { DigitaltransformationComponent } from './solutions/digitaltransformation/digitaltransformation.component';
import { DataanalyticsComponent } from './solutions/dataanalytics/dataanalytics.component';
import { OnlinemarketingComponent } from './solutions/onlinemarketing/onlinemarketing.component';
import { AboutusComponent } from './company/aboutus/aboutus.component';
import { OurteamComponent } from './company/ourteam/ourteam.component';
import { ContactusComponent } from './contactus/contactus.component';
import { RetailComponent } from './industries/retail/retail.component';
import { RealestateComponent } from './industries/realestate/realestate.component';
import { ManufacturingComponent } from './industries/manufacturing/manufacturing.component';
import { MarketingComponent } from './industries/marketing/marketing.component';
import { FinanceComponent } from './industries/finance/finance.component';
import { HospitalityComponent } from './industries/hospitality/hospitality.component';
import { EnquiryComponent } from './contactus/enquiry/enquiry.component';
import { IndustriesComponent } from './industries/industries/industries.component';
import { SolutionsComponent } from './solutions/solutions/solutions.component';


@NgModule({
  declarations: [
    AppComponent,
    WebappheaderComponent,
    WebappfooterComponent,
    HomeComponent,
    DigitaltransformationComponent,
    DataanalyticsComponent,
    OnlinemarketingComponent,
    AboutusComponent,
    OurteamComponent,
    ContactusComponent,
    RetailComponent,
    RealestateComponent,
    ManufacturingComponent,
    MarketingComponent,
    FinanceComponent,
    HospitalityComponent,
    EnquiryComponent,
    IndustriesComponent,
    SolutionsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule ,
    MatCardModule,
    MatSelectModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

<!--====================================================
                       HOME-P
======================================================-->
<div id="home-p" class="home-p pages-head1 text-center">
    <div class="container">
      <h1 class="wow fadeInUp" data-wow-delay="0.1s">We offer a huge opportunity for growth</h1>
      <p>Discover more about Team</p>
    </div><!--/end container-->
  </div> 

<!--====================================================
                    TEAM-P1
======================================================-->
  <section id="team-p1" class="team-p1">
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-sm-6  desc-comp-offer">
          <h2 class="wow slideInLeft" data-wow-delay=".1s">Leadership</h2>
          <div class="heading-border-light"></div> 
        </div>
      </div>  
      <div class="row">
         <div class="col-md-6">
           <div class="team-p1-cont wow fadeInUp" data-wow-delay="0.3s">
             <img src="assets/images/team/t-2.jpg" class="img-fluid" alt="...">
             <h5>Savita Shah</h5>
             <h6>CEO & Cofounder</h6>

            </div>
         </div>
         <div class="col-md-6">
           <div class="team-p1-cont wow fadeInUp" data-wow-delay="0.6s">
             <img src="assets/images/team/t-5.jpg" class="img-fluid" alt="...">
             <h5>Sandeep Sangoi</h5>
             <h6>CFO &amp; Cofounder</h6>
            </div>
         </div>
      </div>
    </div>      
  </section> 

<!--====================================================
                    TEAM-P2 
======================================================-->
  <section id="comp-offer" class="team-p2">
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-sm-6  desc-comp-offer">
          <h2 class="wow slideInLeft" data-wow-delay=".1s">Management</h2>
          <div class="heading-border-light"></div> 
        </div>
      </div>  
      <div class="row">  
        <div class="col-md-4 col-sm-6 ">
          <div class="team-p2-cont wow fadeInUp" data-wow-delay="0.2s">
             <img src="assets/images/team/t-1.jpg" class="img-fluid" alt="...">
             <h5>Seema Gill</h5>
             <h6>Sales & Marketing Director</h6>
            </div>
        </div>
        <div class="col-md-4 col-sm-6 ">
          <div class="team-p2-cont wow fadeInUp" data-wow-delay="0.4s">
             <img src="assets/images/team/t-3.jpg" class="img-fluid" alt="...">
             <h5>Steve Woods</h5>
             <h6>Technical Director</h6>
            </div>
        </div>
        <div class="col-md-4 col-sm-6 ">
          <div class="team-p2-cont wow fadeInUp" data-wow-delay="0.6s">
             <img src="assets/images/team/t-4.jpg" class="img-fluid" alt="...">
             <h5>Jesia Jones</h5>
             <h6>Creative Director</h6>
            </div>
        </div> 
      </div>
    </div>
  </section>



<!--====================================================
                     NEWS
======================================================-->
  <section id="comp-offer" *ngIf="false">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-3 col-sm-6  desc-comp-offer wow fadeInUp" data-wow-delay="0.2s">
          <h2>Latest News</h2>
          <div class="heading-border-light"></div> 
          <button class="btn btn-general btn-green" role="button">See More</button>
        </div>
        <div class="col-md-3 col-sm-6 desc-comp-offer wow fadeInUp" data-wow-delay="0.4s">
          <div class="desc-comp-offer-cont">
            <div class="thumbnail-blogs">
                <div class="caption">
                  <i class="fa fa-chain"></i>
                </div>
                <img src="assets/images/news/news-1.jpg" class="img-fluid" alt="...">
            </div>
            <h3>Pricing Strategies for Product</h3>
            <p class="desc">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from Business box. </p>
            <a href="#"><i class="fa fa-arrow-circle-o-right"></i> Learn More</a>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 desc-comp-offer wow fadeInUp" data-wow-delay="0.6s">
          <div class="desc-comp-offer-cont">
            <div class="thumbnail-blogs">
                <div class="caption">
                  <i class="fa fa-chain"></i>
                </div>
                <img src="assets/images/news/news-9.jpg" class="img-fluid" alt="...">
            </div>
            <h3>Design Exhibitions of 2017</h3>
            <p class="desc">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from Business box. </p>
            <a href="#"><i class="fa fa-arrow-circle-o-right"></i> Learn More</a>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 desc-comp-offer wow fadeInUp" data-wow-delay="0.8s">
          <div class="desc-comp-offer-cont">
            <div class="thumbnail-blogs">
                <div class="caption">
                  <i class="fa fa-chain"></i>
                </div>
                <img src="assets/images/news/news-12.jpeg" class="img-fluid" alt="...">
            </div>
            <h3>Exciting New Technologies</h3>
            <p class="desc">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from Business box. </p>
            <a href="#"><i class="fa fa-arrow-circle-o-right"></i> Learn More</a>
          </div>
        </div>
      </div>
    </div>
  </section>
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactService } from 'src/app/services/contact.service';

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.css']
})
export class EnquiryComponent implements OnInit {

  public confirmation = false;
  public phonemask: string = '(000) 000-0000';
  public editForm: FormGroup = new FormGroup({
      'firstName': new FormControl("", Validators.required),
      'lastName': new FormControl("", Validators.required),
      'companyName': new FormControl(""),
      'phone': new FormControl("",  Validators.required),
      'emailID': new FormControl("",  [Validators.required, Validators.email]),
      'webURL': new FormControl(""),
      'country': new FormControl(""),
      'industry': new FormControl(""),
      'contactby': new FormControl(""),
      'interestDT': new FormControl(this.returnChecked(1)),
      'interestDA': new FormControl(this.returnChecked(2)),
      'interestOM': new FormControl(this.returnChecked(3)),
      'interestCS': new FormControl(this.returnChecked(4)),
      'comments': new FormControl(""),
      'subscribe': new FormControl(true)
  });

  constructor(private _contactservice: ContactService, @Inject(MAT_DIALOG_DATA) public data: {options :number[]}) { }

  ngOnInit(): void {
  }

  sendEnquiry(){
    console.log(this.editForm.value);
    this._contactservice.enquireRequest(this.editForm.value).subscribe(data => {console.log(data); },
      error => console.log(error),
      () => {console.log('Enquiry Request Submitted'); this.confirmation = true; });
    //this.confirmation = true;
  }


  returnChecked(option:number){
    return this.data.options.includes(5) || this.data.options.includes(option);
  }

}

<!--====================================================
                       HOME-P
======================================================-->
<div id="home-p" class="home-p sdt-head1 text-center">
    <div class="container">
      <h1 class="wow fadeInUp" data-wow-delay="0.1s">We help your buisness stay ahead</h1>
      <p>Our Services</p>
    </div><!--/end container-->
  </div> 

<!--====================================================
                    BUSINESS-GROWTH-P1
======================================================-->
<section class="ftco-section">
    <div class="container">  
    <div style="display: flex;flex-flow: row;margin: 30px;">
          <div style="width:301px;margin: 10px;padding-right:20px;" class="img-fluid wow fadeInUp">
              <img width="300px" style="width:300px;height:auto;" src="assets/images/solutions/digital-transformation-service.png" alt="Digital Transformation">
          </div>
          <div style="width:auto;padding: 20px; text-align: justify; " class="img-fluid wow fadeInRight">
   
  
              <h2 class="wow slideInRight" data-wow-delay=".1s">Digital Transformation</h2>
              <div class="heading-border-light"></div>
  
  
  
                <p>Digital transformation is  all about using digital technologies to create new and improve your existing business processes by requiring businesses to challenge the norm, experiment and become more agile for better customer experiences and providing value to them.
                    It is vital to all businesses, regardless of size. It’s more important than ever to stay competitive and relevant in the digital world.
                </p>

                <p>What isn’t always obvious, however, is what digital transformation actually means. It’s more than just migrating to the cloud; it’s about rethinking old operating models and experimenting in order to become agile in responding to competitors and customers. Because technology plays a vital role in the evolution.</p>
    
                <p>While there may be a multitude of reasons for a business to undergo digital transformation, it mostly boils down to survival. Technology drives the shift in business, and companies that wish to succeed must merge technology with strategy to stay ahead. Data analytics is at the top of the list of priorities, particularly in terms of data visualisation and machine learning.</p>

                <p>These are the operational priorities, but a positive customer experience is the ultimate goal, and is a significant driver for digital transformation. Customer experience can make or break a business, and digital technologies play a vital role in ensuring a positive relationship between businesses and their customers.</p>
      </div>
    </div>
  <div >
<!--====================================================
                      BUSINESS-GROWTH-P1
======================================================-->
<section id="business-growth-p1" class="business-growth-p1 ">
    <div class="container">
      <div class="row title-bar" style="padding: 0px 0px 0px 0px;">
        <div class="col-md-12">
          <h1 class="wow fadeInUp">We committed to helping</h1>
          <div class="heading-border"></div>
          <p class="wow fadeInUp" data-wow-delay="0.4s">We help clients redefine customer experience, explore new operating models, create enhanced digital channels, and provide tools required to compete in today’s marketplace.<br/>
            Call us today! Reshape, Reinvent and Stand out as the leader in the digital business world!
          </p>
        </div>
      </div>
      <div class="row wow animated fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
        <div class="col-md-3 col-sm-6 service-padding">
            <div class="service-item">
                <div class="service-item-icon"> <i class="fa fa-laptop fa-3x"></i>
                </div>
                <div class="service-item-title">
                    <h3>Digital Commerce & Portals</h3>
                </div>
                <div class="service-item-desc">
                    <p>Next generation sites and app for better customer engagement.</p> 
                    <div class="content-title-underline-light"></div> 
                </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 service-padding">
            <div class="service-item">
                <div class="service-item-icon"> <i class="fa fa-cloud fa-3x"></i>
                </div>
                <div class="service-item-title">
                    <h3>Cloud Computing</h3>
                </div>
                <div class="service-item-desc">
                    <p>Cloud helps small businesses operate more effectively and Scale on-demand</p>
                    <div class="content-title-underline-light"></div> 
                </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 service-padding">
            <div class="service-item">
                <div class="service-item-icon"> <i class="fa fa-database fa-3x"></i>
                </div>
                <div class="service-item-title">
                    <h3>Business Analytics</h3>
                </div>
                <div class="service-item-desc">
                    <p>Learn incredibly detailed insights into all aspects of your business.</p>
                    <div class="content-title-underline-light"></div> 
                </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 service-padding">
            <div class="service-item right-bord">
                <div class="service-item-icon"> <i class="fa fa-search fa-3x"></i>
                </div>
                <div class="service-item-title">
                    <h3>Online Marketing</h3>
                </div>
                <div class="service-item-desc">
                    <p>Expand your business to next level be found online, reach locally and globally.</p>
                    <div class="content-title-underline-light"></div> 
                </div>
            </div>
        </div> 
      </div>
    </div>  
  </section>   
  </div>
  </div>
  </section>
<!--====================================================
                       HOME-P
======================================================-->
<div id="home-p" class="home-p pages-head1 text-center">
    <div class="container">
        <h1 class="wow fadeInUp" data-wow-delay="0.1s">Enabling you unlock the power of digital</h1>
        <p>Discover more about us</p>
    </div>
    <!--/end container-->
</div>

<!--====================================================
                    ABOUT-P1
======================================================-->
<section id="about-p1">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="about-p1-cont">
                    <h1>Who we are </h1>
                    <p> Founded in 1995, BizTech is a provider of IT consulting and software development services. Having started as a small product company, we switched to IT services in 2002 and ever since we have helped service organizations and product companies improve business performance and quickly win new customers.</p>
                    <p>BizTech's seasoned leadership team sets the standard for all global employees by demonstrating how transparency, collaboration, and accountability foster better business outcomes. Through passion and problem solving, they drive continual progress for our customers.</p>
                    <p>With over 25 years in Information Technology, we have built up expertise in Portals, Ecommerce, Mobile Apps, Data Analytics, CRM, Online Marketing and other areas and conquered such innovative fields as Artificial Intelligence, Machine Learning, Social media management and Internet of Things. </p>
                    <p>We headquarter in Kendall Park, NJ, US, have office in London and a delivery center in India. Having gained trust among companies operating around the world.</p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="about-p1-img">
                    <img src="assets/images/ipad-tablet.png" class="img-fluid wow fadeInUp" data-wow-delay="0.1s" alt="...">
                </div>
            </div>
        </div>
    </div>
</section>

<!--====================================================
                   ABOUT-P2
======================================================-->
<section class="about-p2 bg-gradiant">
    <div class="container-fluid">
        <div class="row">
            <div class="about-p2-heading">
                <h1 class="cl-white">global leader in technology services</h1>
                <div class="heading-border-light bg-white"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-12">
                <div class="about-p2-cont cl-white">
                    <img src="assets/images/news/news-5.jpg" class="img-fluid wow fadeInUp" data-wow-delay="0.1s" alt="...">
                </div>
            </div>
            <div class="col-md-4">
                <div class="about-p2-cont cl-white wow fadeInUp" data-wow-delay="0.3s">
                    <h5>Digital Transformation</h5>
                    <p class="cl-white">Evolving needs and customer expectations challenge even the most agile organizations to keep up in the Digital Age. To survive, organizations must constantly innovate and transform. <br/><br/>We help clients redefine customer experience by create enhanced digital channels required to compete in today’s marketplace.</p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="about-p2-cont cl-white wow fadeInUp" data-wow-delay="0.5s">
                    <h5>Analytics &amp; Marketing</h5>
                    <p class="cl-white">Your marketing campaigns shouldn’t be a shot in the dark. With the right data-driven marketing strategies, you can craft and release marketing campaigns that speak directly to your audience, building valuable trust and loyal customer.<br/><br/>We help you build Data-driven marketing using data to inform all marketing decisions, from creative assets to campaigns. It places customer data front and center to ensure all marketing efforts are relevant to customer interests and behaviors</p>
                </div>
            </div>
        </div>
    </div>
</section>

<!--====================================================
                 ABOUT-P3
======================================================-->
<section id="story" class="about-p3">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="story-desc">
                    <h3>Our Misson</h3>
                    <div class="heading-border-light"></div>
                    <p>Our mission is to offer total, cost-effective, next generation <span style="text-decoration: underline;">digital transformation</span> and software solutions in the shortest possible development time. As a result, our clients are able to launch their product ideas early.</p>
                    <p>Deliver scalable digital products and software solutions to unlock the power of customer-centric intelligent technology that reduces the time to market and reimagines cost takeout initiatives. </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="story-himg">
                    <img src="assets/images/img/image-3.jpg" class="img-fluid wow fadeInUp" data-wow-delay="0.1s" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="container ">
        <div class="row">
            <div class="col-md-6 ">
                <div class="story-himg story-himg-middle-u">
                    <img src="assets/images/img/image-2.jpg" class="img-fluid wow fadeInUp" data-wow-delay="0.1s" alt="">
                </div>
            </div>
            <div class="col-md-6">
                <div class="story-desc story-desc-middle ">
                    <h3>Our Vision</h3>
                    <div class="heading-border-light"></div>

                        <ul>
                            <li>We want to lead in digital transformation and software solutions, and be known as an IT services company of repute</li>
                            <li>BizTech must build strategic partnerships globally with all stakeholders: clients, vendors, and investors</li>
                            <li>Our goal is to stay abreast with technology and build our technical competence and domain expertise</li>
                            <li>We need to nurture a winning team that has a passion for excellence</li>
                            <li>BizTech needs to be the delight of our customers by achieving perfection in our processes and quality methods    </li>
                        </ul>
                 </div>
            </div>
            <div class="col-md-6 ">
                <div class="story-himg story-himg-middle-l">
                    <img src="assets/images/img/image-2.jpg" class="img-fluid" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="story-desc">
                    <h3>Our Values</h3>
                    <div class="heading-border-light"></div>
                    <p>Create business excellence by staying committed to core values of generating enterprise value and imbibing the spirit of innovation, forward-thinking, dependability, and integrity with the accountability to drive digital transformation forward.</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="story-himg">
                    <img src="assets/images/img/image-1.jpg" class="img-fluid wow fadeInUp" data-wow-delay="0.1s" alt="">
                </div>
            </div>
        </div>
    </div>
</section>
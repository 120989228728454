<!--====================================================
                       HOME-P
======================================================-->
<div id="home-p" class="home-p pages-head4 text-center">
    <div class="container">
      <h1 class="wow fadeInUp" data-wow-delay="0.1s">Contact Us</h1>
    </div><!--/end container-->
  </div> 

<!--====================================================
                      CONTACT-P1  
======================================================--> 
<section class="ftco-section contact-section ftco-degree-bg" style="margin: 20px;">
    <div class="container">
  
      <div class="row block-6 ">
        <div class="col-md-6 pr-md-5 img-fluid wow fadeInLeft">
          <div fxLayout="column" fxFlex="100" *ngIf="confirmation">

            <h2>Request Submitted</h2>
            <p>Thanks for reaching out, we're on it! And we'll respond as soon as possible</p>
            <p>Our representiative will reachout shortly on:<br>
              Email: <span style="color: navy;" >{{data.cemail}}</span> <br>
              Phone: <span style="color: navy;" >{{data.cphone }}</span>
            </p>
            <p>
              Meanwhile, please visit our website section below for more details:<br><br>
              <mat-nav-list>
                <span style="align-items: center;"><a routerLink='/home'>
                    <mat-icon>home</mat-icon> Home Page
                  </a></span> <br>
                <span style="align-self: center ;"><a routerLink='/retail'>
                    <mat-icon>live_help</mat-icon> Industries
                  </a></span><br>
                <span style="vertical-align: middle ;"><a routerLink='/digitaltransformation'>
                    <mat-icon>toys</mat-icon> Solutions & Services
                  </a></span><br>
                <span style="vertical-align: middle ;"><a routerLink='/aboutus'>
                    <mat-icon>face</mat-icon> About Us
                  </a></span>
              </mat-nav-list>
            </p>
          </div>
          <diV *ngIf="!confirmation">
            <div class="career-p1-desc">
                  <h4 class="wow slideInLeft" data-wow-delay=".1s">Contact Information</h4>
                  <div class="heading-border-light"></div>
              </div>
              How can we help YOU succeed?<br>
              <form name="contform" (ngSubmit)="contform.form.valid && onSubmit()" #contform="ngForm" novalidate>
                  <mat-form-field class="full-width">
                    <input name="cname" required minlength="4" matInput placeholder="Full Name" [(ngModel)]="data.cname" #cname="ngModel"  autofocus >
                    <mat-error *ngIf="cname.invalid && cname.touched">{{getErrorMsg("cname",cname.errors)}}"</mat-error>
                  </mat-form-field>
                  <mat-form-field class="full-width">
                    <input name="cemail" type="email" required email matInput placeholder="Email Address"  [(ngModel)]="data.cemail" #cemail="ngModel" >
                    <mat-icon matSuffix>email</mat-icon>
                    <mat-error *ngIf="cemail.invalid && cemail.touched ">{{getErrorMsg("cemail",cemail.errors)}}"</mat-error>
                  </mat-form-field>
                  <mat-form-field class="full-width">
                    <input name="cphone" type="tel" matInput placeholder="Mobile Number (Inculde Country Code)" maxlength="17" pattern="^[0-9-+ ()]*$" [(ngModel)]="data.cphone" #cphone="ngModel">
                    <mat-icon matSuffix>phone</mat-icon>
                    <mat-error *ngIf="cphone.invalid && cphone.touched ">Invalid Phone Number</mat-error>
                  </mat-form-field>
                  <mat-form-field class="full-width">
                    <mat-label>Interested In</mat-label>
                    <mat-select name="cinterest" [(ngModel)]="data.cinterest" #cinterest="ngModel" >
                        <mat-option value="General">General</mat-option>
                        <mat-option value="Digital Transformation" selected>Digital Transformation</mat-option>
                      <mat-option value="Data Analytics">Data Analytics</mat-option>
                      <mat-option value="Online Marketing">Online Marketing</mat-option>
                      <mat-option value="Other">Other</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="full-width">
                      <mat-label>How did you hear about us?</mat-label>
                      <mat-select name="caboutus" [(ngModel)]="data.caboutus" #caboutus="ngModel" >
                          <mat-option value="Google Search">Google Search</mat-option>
                          <mat-option value="Social Media" selected>Social Media</mat-option>
                        <mat-option value="Link from Website">Link from Website</mat-option>
                        <mat-option value="Friend / Referral">Friend / Referral</mat-option>
                        <mat-option value="Other">Other</mat-option>
                      </mat-select>
                    </mat-form-field>
                  <mat-form-field class="full-width">
                    <textarea name="cdetails" matInput placeholder="Message" miltiline="true" rows="5" [(ngModel)]="data.cdetails" #cdetails="ngModel" ></textarea>
                  </mat-form-field>
                  <button [disabled] ="!contform.form.valid" type="submit" (click)="onSubmit()" mat-raised-button color="primary">Send Message</button>
                </form>
              </diV>
        </div>
  
        <div class="col-md-6" id="map">
          <mat-card class="contact-card img-fluid wow fadeInUp" >
            <mat-card-header>
              <div mat-card-avatar class="header-image-sas"></div>
              <mat-card-title>Savita Shah</mat-card-title>
              <mat-card-subtitle>CEO & Cofounder</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <div class="row block-9">
                <div class="col-md-6">
                  10 Labrador Dr,<br>
                  Kendall Park, <br>
                  NJ 08824, <strong>USA</strong>
                </div>
                <div class="col-md-6">
                  <mat-icon style="font-size: 14px !important">phone </mat-icon><a href="tel:+1 (732) 470-0007">+1 (732)
                    470-0007</a><br>
                  <mat-icon style="font-size: 14px !important">email </mat-icon><a
                    href="mailto:savita@biztechpeople.com">savita@biztechpeople.com</a>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
          <br>
          <mat-card class="contact-card img-fluid wow fadeInUp">
            <mat-card-header>
              <div mat-card-avatar class="header-image-jv"></div>
              <mat-card-title>Jigna Venkatramanan</mat-card-title>
              <mat-card-subtitle>President & Cofounder</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <div class="row block-9">
                <div class="col-md-6">
                  70 Park Lane,<br>
                  South Harrow, Harrow <br>
                  Middlesex, HA2 8NG,<strong>UK</strong> 
                </div>
                <div class="col-md-6">
                  <mat-icon style="font-size: 14px !important">phone </mat-icon><a href="tel:+44 (7826) 942102">+44 (7826)
                    942102</a><br>
                  <mat-icon style="font-size: 14px !important">email </mat-icon><a
                    href="mailto:jigna@biztechpeople.com">jigna@biztechpeople.com</a>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
          <br>
          <mat-card class="contact-card img-fluid wow fadeInUp">
            <mat-card-header>
              <div mat-card-avatar class="header-image-ss"></div>
              <mat-card-title>Sandeep Sangoi</mat-card-title>
              <mat-card-subtitle>CFO & Cofounder</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <div class="row block-9">
                <div class="col-md-6">
                  C 4/5, Dahisar Bhavana,<br>
                  S. V. Road, Dahisar East <br>
                  Mumbai, 400 068, <strong>India</strong>
                </div>
                <div class="col-md-6">
                  <mat-icon style="font-size: 14px !important">phone </mat-icon><a href="tel:+91 (98204) 45533">+91
                    (98204) 45533</a><br>
                  <mat-icon style="font-size: 14px !important">email </mat-icon><a
                    href="mailto:sandeep@biztechpeople.com">sandeep@biztechpeople.com</a>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
          <br>
        </div>
      </div>
    </div>
  </section>
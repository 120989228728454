<!--====================================================
                       HOME-P
======================================================-->
<div id="home-p" class="home-p pages-head2 text-center">
    <div class="container">
      <h1 class="wow fadeInUp" data-wow-delay="0.1s">We understand your industry</h1>
      <p>Real Estate</p>
    </div><!--/end container-->
  </div> 

<!--====================================================
                      CAREER-P1
======================================================-->

  <section id="about" class="career-p1 about">
    <div class="container">
      <div class="row"> 

        <div class="col-md-6 img-fluid wow fadeInLeft">
          <div class="career-p1-desc">
            <h4 class="wow slideInLeft" data-wow-delay=".1s">Real Estate</h4>
            <div class="heading-border-light"></div>
            <p>Digital transformation is taking place across the global economy, and real estate sector is no exception. Leading real estate companies are using modern technology to improve customer experiences, boost sales and increase operational efficiencies. Yet many real estate firms have been slow to keep up with adopting new tools and technologies to transform their companies.</p>
            <p>A real estate management solution should effectively integrate activities related to investment and constructions, repairs & maintenance and lease & space management.</p> 
            <p>Get the technical support you need to efficiently run your property operations. Generate more revenue with optimized processes that deliver better solutions to the property consultants and buyers.</p>
            <p>With shrinking availability of physical space, technology-enabled real estate service providers are going to rule the markets.</p>
            <p>Our solutions make use of industry best practices to let you optimize your work processes for best efficiency. Minimize costs, mitigate risk and comply with statutory regulations with centralized access to data, documents and information.</p>
          </div>
        </div>  
        <div class="col-md-6" >
            <div class="career-p1-himg" >
              <img src="assets/images/industries/real-estate_industry.jpg" class="img-fluid wow fadeInUp" data-wow-delay="0.1s" alt="">
            </div>
          </div>
      </div>
    </div> 
  </section>  

<!--====================================================
                      CAREER-P2
======================================================-->
  <div class="overlay-career-p2"></div>
  <section id="thought" class="bg-parallax career-p2-bg">
    <div class="container">
      <div id="row" class="row title-bar-career-p2">
        <div class="col-md-4 ">
          <h1 >100%</h1>
        </div>  
        <div class="col-md-8 ">
          <p class="wow fadeInUp " data-wow-delay="0.4s">Experience the power of complete real estate management lifecycle with our solutions built and delivered with flexibility, enabling you to choose the level of structure you need. Manage your space administration and recurring lease activities in an efficient way</p>
        </div> 
      </div>
    </div>         
  </section> 

<!--====================================================
                     CAREER-P3
======================================================--> 
  <div class="overlay-career-p3"></div>
   <section id="career-p3">
     <div class="container-fluide">
       <div class="row career-p3-title">
        <div class="col-md-12">
         <h3 class="text-center">Our Solutions</h3>
        </div> 
       </div>
     </div>
   </section> 

   <section class="what-we-do bg-gradiant">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-globe"></i>
                <h6>Websites & Mobile Apps</h6>
                <p class="desc">Responsive websites and apps for online estate portfolis</p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-users"></i>
                <h6>Customer Management</h6>
                <p class="desc"> Get insight on consumer behaviors & expectations. </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-file-invoice-dollar"></i>
                <h6>Property Management</h6>
                <p class="desc">End to end property lifecycle workflow </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-warehouse"></i>
                <h6>Lease Management</h6>
                <p class="desc">Complete Lease management for rental properties. </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-tags"></i>
                <h6>Price and Promotions</h6>
                <p class="desc">Effective prices management as wells as promotion exeuction for sales. </p>
              </div>
            </div>
            <div class="col-md-4  col-sm-6">
              <div class="what-we-desc">
                <i class="fa fa-comments"></i>
                <h6>Reviews and Feedback</h6>
                <p class="desc">Solutions for customer reviews and Feedback  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>      
  </section> 
